<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">TenWeeks</h2>
          </router-link>
        </v-card-title>
        <v-card-text>
          <v-tabs grow v-model="loginType" class="mb-2">
            <v-tab v-if="!isLargeScreen">Customer/Vendor</v-tab>
            <v-tab v-if="isLargeScreen">Admin</v-tab>
          </v-tabs>
        
        <v-tabs-items v-model="loginType">
        <v-tab-item v-if="!isLargeScreen">
          <CustomerLogin />
        </v-tab-item>
        <v-tab-item v-if="isLargeScreen">
          <AdminLogin />
        </v-tab-item>
        </v-tabs-items>
        </v-card-text>
        
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
import CustomerLogin from '@/views/pages/CustomerLogin'
import AdminLogin from '@/views/pages/Login'
export default {
  data() {
    return {
      loginType: 0,
      isLargeScreen:window.innerWidth > 960
    }
  },
  components: { CustomerLogin, AdminLogin },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
