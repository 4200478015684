<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <label for="Email">Email</label>
    <v-text-field
      v-model="form.email"
      outlined
      placeholder="john@example.com"
      class="mb-3"
      required
      :rules="emailRules"
    ></v-text-field>
    <label for="Password">Password</label>
    <v-text-field
      v-model="form.password"
      outlined
      :type="isPasswordVisible ? 'text' : 'password'"
      placeholder="············"
      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
      @click:append="isPasswordVisible = !isPasswordVisible"
      required
      :rules="passwordRules"
    ></v-text-field>

    <!--<div class="d-flex align-center justify-space-between flex-wrap">
							 <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"> </v-checkbox> -->

    <!-- forgot link -->
    <!-- <a href="javascript:void(0)" class="mt-1"> Forgot Password? </a>
						</div> -->

    <v-btn block color="primary" v-on:click="formSubmit" class="mt-3" :loading="loading" :disabled="loading">
      Login
    </v-btn>
  </v-form>
</template>

<script>
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, reactive } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import Util from '@/utils/axios-util.js'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const loading = ref(false)
    const form = reactive({
      email: '',
      password: '',
    })
    const valid = ref(false)
    const emailRules = ref([v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'Please enter valid E-mail.'])
    const passwordRules = ref([
      v => !!v || 'Password is required',
      v => (v && v.length >= 8) || 'Password should contain minimum 8 characters.',
    ])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      loading,
      form,
      emailRules,
      passwordRules,
      valid,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'processing', 'loginError']),
  },
  methods: {
    ...mapActions(['login', 'externalLogin', 'signOut', 'clearSession']),
    formSubmit() {
      var e = this

      let flag = e.$refs.form.validate()

      if (flag) {
        e.loading = true
        let obj = {
          email: e.form.email,
          password: e.form.password,
        }
        e.login(obj)
          .then(function(response) {
            let token = e.currentUser.token

            Util.Axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : ''

            if (e.currentUser.isAdmin) e.$router.push({ name: 'dashboard' })
            else e.$router.push({ name: 'payments' })
          })
          .catch(function(response) {})
          .finally(function() {
            e.loading = false
          })
      }
    },
  },
  created() {
    this.clearSession()
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
